<template>
  <div class="data_point">
    <h3>Add Data Point</h3>
    <div class="tag">
      <p>Location:</p>
      <locationDrop class="locationDropDisp" :startingId="'0'" :states="locationStates" :enabled="locationDropDownEnabled"
                    @selection="locationSelected"></locationDrop>
    </div>

    <div class="tag">
      <p>Vehicle:</p>
      <vehicleDrop class="vehicleDropDisp" :startingId="'0'" :states="vehicleStates" :enabled="vehicleDropDownEnabled"
                  @selection="vehicleSelected"></vehicleDrop>
    </div>

    <div class="tag">
      <p>Group:</p>
      <tagGroupDrop class="tagGroupDropDisp" :startingId="'0'" :states="tagGroupStates" :enabled="tagGroupDropDownEnabled"
                    @selection="tagGroupSelected"></tagGroupDrop>
    </div>

    <div class="tag">
      <p>Tag:</p>
      <tagDrop class="tagDropDisp" :startingId="'0'" :states="tagStates" :enabled="tagDropDownEnabled"
              @selection="tagSelected"></tagDrop>
    </div>

    <button class="button" @click="addSelected">ADD</button>
    <button class="button secondary" style="float: right" @click="cancelDialog">Cancel</button>
  </div>
</template>

<script setup>
import {ref, defineEmits, onMounted, defineProps} from 'vue';

const emit = defineEmits(['selected','cancel']);
const props = defineProps({locationId: String, vehicleId: String});

//----------------- LOCATION AND VEHICLE DROP DOWN SELECTION -------------------------------
let currentlySelectedGroupId = "";
let currentDropletId = "";
let locationDropDownEnabled = ref(false);
let vehicleDropDownEnabled = ref(false);
let tagGroupDropDownEnabled = ref(false);
let tagDropDownEnabled = ref(false);

import LocationDrop from '../../components/dropdowns/dropdown1.vue';
import VehicleDrop from '../../components/dropdowns/dropdown1.vue';
import TagGroupDrop from '../../components/dropdowns/dropdown1.vue';
import TagDrop from '../../components/dropdowns/dropdown1.vue';

let locationStates = ref({});
let vehicleStates = ref({});
let tagGroupStates = ref({});
let tagStates = ref({});

const getLocationsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
const getVehicleUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?dropSel&location=";
const getTagGroupsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?groupSel&droplet=";
const getTagsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?tagSel&group=";

onMounted(() => {
  vehicleSelected(props.vehicleId);
  locationSelected(props.locationId);
})

fetch(getLocationsUrl, {
  headers: {'Content-type': 'application/json'},
}).then((res) => res.json()).then((response) => {
  //console.log("location",response);
  locationStates.value = response;
  locationDropDownEnabled.value = true;
}).catch((error) => {
  console.log("LOCATION FETCH ERROR " + error);
});

let currentlySelectedLocationId = "";

function locationSelected(id) {
  currentlySelectedLocationId = id;
  fetch(getVehicleUrl + id, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    vehicleStates.value = response;
    vehicleDropDownEnabled.value = true;
  }).catch((error) => {
    console.log("VEHICLE FETCH ERROR " + error);
  });
}

function vehicleSelected(id) {
  currentDropletId = id;
  //console.log("DROPLETID",currentDropletId);
  fetch(getTagGroupsUrl + id, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    tagGroupStates.value = response;
    tagGroupDropDownEnabled.value = true;
  }).catch((error) => {
    console.log("TAG GROUP FETCH ERROR " + error);
  });
}

//----------------------------END OF LOCATION AND VEHICLE DROP DOWNS -----------------------------


//----------------- GROUP AND TAG DROP DOWN SELECTION -------------------------------
let currentlySelectedTagId = "";

function tagGroupSelected(id) {
  currentlySelectedGroupId = id;
  fetch(getTagsUrl + id, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    //console.log("tags",response);
    tagStates.value = response;
    tagDropDownEnabled.value = true;
  }).catch((error) => {
    console.log("TAGS FETCH ERROR " + error);
  });
}

function tagSelected(id) {
  currentlySelectedTagId = id;
}

function addSelected() {
  emit('selected', {
        "group": tagGroupStates.value[currentlySelectedGroupId],
        "droplet": currentDropletId,
        "tag": currentlySelectedTagId,
        "name": /*vehicleStates.value[currentDropletId] + " " + */tagStates.value[currentlySelectedTagId],
        "location": locationStates.value[currentlySelectedLocationId]
      }
  );
}
function cancelDialog() {
  emit('cancel', {});
}

//----------------- END OF GROUP AND TAG DROP DOWN SELECTION -------------------------------

</script>

<style lang="scss">
h3{
  margin-top: 0;
}
.tag {
  display: flex;
  margin-top: 16px;

  p {
    width: 104px;
    flex-shrink: 0;
    height: 36px;
    line-height: 36px;
    margin: 0px;
  }

  div {
    flex-grow: 1;
    height: fit-content;

    select {
      height: 36px;
      box-sizing: border-box;
      padding: 0px;
      border-radius: 8px;
    }
  }
}

button {
  width: calc(50% - 8px);
  margin-top: 20px;
}
</style>