<template>
  <div class="content">
    <h3 @click="here">Data Trending</h3>
    <div class="plotarea">
      <div class="plotarea_header">
        <profileDrop
            class="profiledropdown"
            :startingId=startingProfileName
            :states=profileStates
            :enabled=profileDropDownEnabled
            @selection="ProfileSelected">
        </profileDrop>
        Start&nbsp;<input type="date" class="datepicker" @change="handleSelectDateStart"/>
        End&nbsp;<input type="date" class="datepicker" @change="handleSelectDateEnd"/>
        <div style="display: flex; margin-left: auto">
          <div v-if="loadneeded" @click="LoadSeriesData()" class="button">Load Series data</div>
          <div class="profile_settings" @click="profile = !profile"></div>
        </div>
      </div>
      <!-- <div v-if="loadneeded" class="loaddatabutton" @click="LoadSeriesData()">Load Series Data</div> -->
      <div class="dataspinner" v-if="spinner"><img width="200" src="../../../public/img/giphy.gif"/><span><br>LOADING DATA...</span>
      </div>
      <div class="profilespinner" v-if="spinnerProfile"><br>LOADING PROFILE...</div>
      <div style="height: calc(100% - 76px);width:100%;">
        <highcharts v-if="!spinner" class="highcharts-class" style="height: 100%" :options="chartOptions" ref="highcharts"></highcharts>
      </div>
    </div>

    <div class="profiletile" v-if="profile" :style="save || saved || showTagDialog || showConfigDialog || deleteProfileModal || deleteRow >= 0 ? {zIndex : '1'} : null">
      <div class="profiletile_top">
        <h3>Profile Settings</h3>
        <span @click="profile = !profile"></span>
      </div>
      <p v-if="currentProfile != ''">{{ currentProfile }}</p>
      <div class="profilebuttons">
        <div @click="NewProfile">New</div>
        <div @click="SaveProfile">Save</div>
        <div @click="save = !save">Save As</div>
        <div @click="deleteProfileModal = true">Delete</div>
        <!-- <div @click="DeleteProfile">Delete</div> -->
      </div>
      <div class="profiletilelist">
        <template v-for="row in seriesParams">
          <div class="profile_line">
            <Toggle v-if="seriesCountNumber < 5 || row.parameters.enabled" @click="StylePlots();" v-model="row.parameters.enabled"/>
            <Toggle v-else @click="showErrorMessage('You can only have 5 series enabled');" v-model="row.parameters.enabled" disabled/>
            <span class='linecolor' :style="{ backgroundColor: row.parameters.color}"></span>
            <span class="line_name">{{ row.name }}</span>
            <div class="listbuttons">
              <a class="listbutton" @click="LoadConfigDialog(seriesParams.indexOf(row))"><img
                  src="../../../public/img/library/edit.svg"></a>
              <a class="listbutton" @click="deleteRow = seriesParams.indexOf(row)"><img
                  src="../../../public/img/library/delete.svg"></a>

            </div>
          </div>
        </template>
      </div>
      <div class="button_wrapper">
        <div class="button add_tag_button" @click="showTagDialog=true">add tag</div>
      </div>
    </div>

    <div class="save" v-if="save">
      <h3>Save Profile As <span @click="save = !save"></span></h3>
      <div class="profile_name">
        <p>Name</p>
        <input type="text" placeholder="e.g. LCL 2" v-model="saveProfileName">
      </div>
      <div class="buttons">
        <button class="button secondary" @click="save = !save">Cancel</button>
        <button class="button" @click="SaveAsProfile()">Save</button>
      </div>
    </div>

    <div class="saved_prof" v-if="saved">
      <h3>Saved</h3>
    </div>

    <div class="saved_prof" v-if="deleted">
      <h3>Deleted</h3>
    </div>

    <div class="configpanel" v-if="showConfigDialog">
      <div class="configpanel_top">
        <h3>Tag Settings</h3>
        <span @click="showConfigDialog = false"></span>
      </div>
      <div>
        <p>Tag</p>
        <p>{{ tempConfig.name }}</p>
      </div>
      <div>
        <p>Color</p>
        <ColorPicker format="rgb" shape="square" v-model:pureColor="tempConfig.color"
                     v-on:pureColorChange="(c) => {tempConfig.color=c; closeColorPickerOnClick()}"/>
      </div>
      <div>
        <p>Enabled</p>
        <Toggle v-model="tempConfig.enabled"/>
      </div>
      <div>
        <p>Show Y-Axis</p>
        <Toggle v-model="tempConfig.yaxis"/>
      </div>
      <div>
        <p>Min</p><input type="text" v-model="tempConfig.min"/>
      </div>
      <div>
        <p>Max</p><input type="text" v-model="tempConfig.max"/>
      </div>
      <div>
        <p>Line Style</p>
        <lineTypeDrop :startingId="tempConfig.dashstyle" :states=availableLineTypeStates :enabled=true
                      @selection="(s) => {tempConfig.dashstyle=s}"/>
      </div>
      <div>
        <p>Line Thickness</p><input type="text" v-model="tempConfig.thickness"/>
      </div>
      <div>
        <p>Plot Type</p>
        <plotTypeDrop :startingId="tempConfig.plottype" :states=availablePlotTypeStates :enabled=true
                      @selection="(s) => {tempConfig.plottype=s}"/>
      </div>
      <div>
        <p>Y-Axis on Right</p>
        <Toggle v-model="tempConfig.axisonright"/>
      </div>
      <div>
        <p>Step</p>
        <Toggle v-model="tempConfig.flag"/>
      </div>
      <button class="button" @click="CloseConfigDialog()">Apply</button>
    </div>

    <div class="tagselectpanel" v-if="showTagDialog">
      <tagSelectDialog @selected="tagSelected" @cancel="showTagDialog=false"></tagSelectDialog>
    </div>
  </div>

  <div v-if="deleteRow >= 0" class="delete_anything">
    <h3>Tag Deletion Warning</h3>
    <div class="trash_can"></div>
    <p>Are you sure, that you want to delete the next tag?</p>
    <h3 class="parameter">{{ seriesParams[deleteRow].name }}</h3>
    <p>You can add it back later if you choose.  Click Cancel to exit without deleting, or Delete to confirm deletion.</p>
    <div class="buttons">
      <div class="button secondary" @click="deleteRow = -1">Cancel</div>
      <div class="button" @click="RemoveTag(deleteRow)">Delete</div>
    </div>
  </div>

  <div v-if="deleteProfileModal" class="delete_anything">
    <h3>Profile Deletion Warning</h3>
    <div class="trash_can"></div>
    <p>Are you sure, that you want to delete this profile?</p>
    <h3 class="parameter">{{ currentProfile }}</h3>
    <p>You can add it back later if you choose.  Click Cancel to exit without deleting, or Delete to confirm deletion.</p>
    <div class="buttons">
      <div class="button secondary" @click="deleteProfileModal = false">Cancel</div>
      <div class="button" @click="DeleteProfile()">Delete</div>
    </div>
  </div>

  <div class="context_shadow" v-if="save || saved || deleted || showTagDialog || showConfigDialog || deleteRow >= 0 || profile || deleteProfileModal"
       @click="showTagDialog = false; deleteRow = -1; profile = false; save = false; saved = false; deleteProfileModal = false; CloseConfigDialog();"></div>
</template>

<script setup>
import {ref} from 'vue';
import chartopts from './trendingChartOptions.json';
import profileDrop from '../../components/dropdowns/dropdown2.vue';
import plotTypeDrop from '../../components/dropdowns/dropdown2.vue';
import lineTypeDrop from '../../components/dropdowns/dropdown2.vue';
import tagSelectDialog from '../../components/boeing/tagSelector.vue';
import Toggle from '@vueform/toggle';
import '../../../src/assets/default.scss';
//import { isClient } from '@vueuse/shared';

let defaultParamJson = {
  "color": "blue",
  "yaxis": false,
  "max": null,
  "min": null,
  "dashstyle": "solid",
  "thickness": 1,
  "plottype": "spline",
  "enabled": true,
  "axisonright": false
};
let seriesCountNumber = ref(5);
let seriesParams = ref([]);
let tagToSeriesMap = [];
let deleteRow = ref(-1);
let profile = ref(false);
let showConfigDialog = ref(false);
let deleteProfileModal = ref(false);
let showTagDialog = ref(false);
let loadneeded = ref(false);
let save = ref(false);
let saved = ref(false);
let deleted = ref(false);
let saveProfileName = ref("");
let chartOptions = ref(chartopts);
let startDate = ref("");
let endDate = ref("");
let spinner = ref(false);
let spinnerProfile = ref(false);
let profileDropDownEnabled = ref(false);
let profileStates = ref({});
let currentProfile = "";
let startingProfileName = ref("");
let bdlApiPathBase = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";
let bdlApiPath = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";

import { inject } from 'vue';
let isarchive = inject('isarchive');
if(isarchive==undefined) isarchive="false";
if(isarchive=="true") {
  bdlApiPath = "https://archive.boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";
}

let currentConfigIndex = ref();
let availablePlotTypeStates = ref("");
let availableLineTypeStates = ref("");
let tempConfig = ref(
    {
      name: "",
      color: "blue",
      enabled: false,
      yaxis: false,
      max: null,
      min: null,
      dashstyle: "solid",
      thickness: 1,
      plottype: "spline",
      axisonright: false,
      flag: false
    }
);

//AT LOAD
//profileStates.value = [];
availablePlotTypeStates.value = {
  area: "area",
  spline: "spline",
  areaspline: "areaspline",
  line: "line",
  scatter: "scatter",
  bubble: "bubble"
};

availableLineTypeStates.value = {
  Solid: 'Solid',
  ShortDash: 'ShortDash',
  ShortDot: 'ShortDot',
  ShortDashDot: 'ShortDashDot',
  ShortDashDotDot: 'ShortDashDotDot',
  Dot: 'Dot',
  Dash: 'Dash',
  LongDash: 'LongDash',
  DashDot: 'DashDot',
  LongDash: 'LongDashDot',
  LongDashDotDot: 'LongDashDotDot'
};
ClearSeriesData();
LoadProfileList();

//chartOptions.value.chart.height = '200%'; // 16:8 ratio  (8 / 18 * 100) + 

// function func() {
//   console.log('chart111')
//   document.querySelector('.highcharts-reset-zoom').click();
//   console.log('chart222')
// }

function handleSelectDateStart(event) {
  console.log('date start', event);
  handleSelectedDate("start", event);
}

function handleSelectDateEnd(event) {
  console.log('date end', event)
  handleSelectedDate("end", event);
}

function handleSelectedDate(cal, event) {
  //const selectedDate = Date.parse(d);
  //let dt = new Date(selectedDate);
  let dt = event.target.valueAsDate;
  dt = dt.toISOString();
  dt = dt.split("T");
  if (cal == "start") {
    startDate.value = dt[0];
  } else {
    endDate.value = dt[0];
  }
  IsReloadNeeded();
  console.log('1111111111111111')
}

function IsReloadNeeded() {
  console.log('2222222222')
  console.log("length", seriesParams.value.length);
  if (startDate.value != "" && endDate.value != "" && (startDate.value < endDate.value)) {
    if (seriesParams.value.length > 0) {
      loadneeded.value = true;
    } else {
      loadneeded.value = false;
    }
  } else {
    loadneeded.value = false;
  }
}

function LoadConfigDialog(id) {
  currentConfigIndex = id;
  tempConfig.value.name = seriesParams.value[id].name;
  tempConfig.value.color = seriesParams.value[id].parameters.color;
  tempConfig.value.enabled = seriesParams.value[id].parameters.enabled;
  tempConfig.value.yaxis = seriesParams.value[id].parameters.yaxis;
  tempConfig.value.max = seriesParams.value[id].parameters.max;
  tempConfig.value.min = seriesParams.value[id].parameters.min;
  tempConfig.value.dashstyle = seriesParams.value[id].parameters.dashstyle;
  tempConfig.value.thickness = seriesParams.value[id].parameters.thickness;
  tempConfig.value.plottype = seriesParams.value[id].parameters.plottype;
  tempConfig.value.axisonright = seriesParams.value[id].parameters.axisonright;
  if (seriesParams.value[id].parameters.flag) {
    tempConfig.value.flag = true;
    tempConfig.value.plottype = "area";
  } else {
    tempConfig.value.flag = false;
  }
  showConfigDialog.value = true;
}

function CloseConfigDialog() {
  seriesParams.value[currentConfigIndex].name = tempConfig.value.name;
  seriesParams.value[currentConfigIndex].parameters.color = tempConfig.value.color;
  seriesParams.value[currentConfigIndex].parameters.enabled = tempConfig.value.enabled;
  seriesParams.value[currentConfigIndex].parameters.yaxis = tempConfig.value.yaxis;
  seriesParams.value[currentConfigIndex].parameters.max = tempConfig.value.max;
  seriesParams.value[currentConfigIndex].parameters.min = tempConfig.value.min;
  seriesParams.value[currentConfigIndex].parameters.dashstyle = tempConfig.value.dashstyle;
  seriesParams.value[currentConfigIndex].parameters.thickness = tempConfig.value.thickness;
  seriesParams.value[currentConfigIndex].parameters.plottype = tempConfig.value.plottype;
  seriesParams.value[currentConfigIndex].parameters.axisonright = tempConfig.value.axisonright;
  if (tempConfig.value.flag) {
    seriesParams.value[currentConfigIndex].parameters.flag = true;
    seriesParams.value[currentConfigIndex].parameters.plottype = "area";
    seriesParams.value[currentConfigIndex].parameters.step = "left";
  } else {
    seriesParams.value[currentConfigIndex].parameters.flag = false;
    seriesParams.value[currentConfigIndex].parameters.step = null;
  }
  showConfigDialog.value = false;
  StylePlots();
}

function LoadSeriesData() {
  spinner.value = true;
  const taglist = JSON.parse(JSON.stringify(seriesParams.value));

  //TRICKY WAY TO REMOVE ITEMS THAT ARE NOT ENABLED
  let i = 0;
  for (i = taglist.length - 1; i >= 0; i -= 1) {
    if (taglist[i].parameters.enabled !== true) {
      taglist.splice(i, 1);
    }
  }

  //REMOVE THE PARAMETERS AND NAME FROM THE LIST BECAUSE THAT BACKEND DOESN"T NEED THEM
  //AND CREATE TAGTOSERIESMAP
  let seriesId = 0;
  taglist.forEach((value) => {
    tagToSeriesMap[seriesId] = value.tag;
    delete value.parameters;
    delete value.name;
    seriesId++;
  });

  const url = bdlApiPath + 'getDataTrendingV8.php?tags=' + JSON.stringify(taglist) + '&start=' + startDate.value + '&end=' + endDate.value;
  fetch(url, {
    method: 'Post',
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    RenderSeriesData(response);
    StylePlots();
    spinner.value = false;
    loadneeded.value = false;
  }).catch((error) => {
    console.log("error loading series data:", error);
    spinner.value = false;
  });
}

function LoadProfileList() {
  const url = bdlApiPathBase + 'getProfileV8.php?screen=trending';
  fetch(url, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    console.log("profilelist", response);
    profileStates.value = response;
    profileDropDownEnabled = true;
  }).catch((error) => {
    console.log("error in loading profile list:", error);
  });
}

function ProfileSelected(p) {
  chartOptions.value.yAxis.forEach((element) => {
    element.visible = false;
  });
  currentProfile = p;
  LoadProfile();
}

function LoadProfile() {
  spinnerProfile.value = true;
  ClearSeriesData();
  const url = bdlApiPathBase + 'getProfileV8.php?scrn=trending&name=' + currentProfile;
  fetch(url, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    seriesParams.value = response;
    console.log("seriespa", response);
    IsReloadNeeded();
    CleanProfile();
    StylePlots();
    spinnerProfile.value = false;
  }).catch((error) => {
    console.log("error in loading profile:", error);
    ClearSeriesData();
    spinnerProfile.value = false;
  });
}

function NewProfile() {
  ClearSeriesData();
  LoadProfileList();
  seriesParams.value = [];
  startingProfileName.value = "...";
  currentProfile = "";
}

function here() {
  console.log(currentProfile,'currentProfile')
}

function SaveProfile() {
  if (currentProfile == "") {
    SaveAsProfile();
  } else {
    const url = bdlApiPathBase + 'setProfileV8.php';
    console.log("profilename",currentProfile);
    console.log("list",seriesParams.value);
    fetch(url, {
      headers: {'Content-type': 'application/json'},
      method: 'POST',
      body: JSON.stringify({
        "action": "save",
        "profilename": currentProfile,
        "list": seriesParams.value
      })
    }).then((res) => res.json()).then((response) => {
      if (response.result) {
        console.log("saveprofile result",response.result);
        ClearSeriesData();
        LoadProfileList();
        saved.value = true;
        setTimeout(() => { saved.value = false; }, 1000);
      } else {
        alert("There was an failure when attempting to save the profile.")
      }
    }).catch((error) => {
      console.log('Looks like there was a problem in SaveProfile: \n', error);
    });
  }
}

function SaveAsProfile() {
  let name;
  if (saveProfileName.value != '') {
    name = saveProfileName.value;
  } else { 
    return
  }
  const url = bdlApiPathBase + 'setProfileV8.php';
  fetch(url, {
    headers: {'Content-type': 'application/json'},
    method: 'POST',
    body: JSON.stringify({
      "action": "saveas",
      "profilename": name,
      "list": seriesParams.value
    })
  }).then((res) => res.json()).then((response) => {
    console.log("result", response);
    if (response.result) {
      startingProfileName.value = name;
      currentProfile = name;
      ClearSeriesData();
      LoadProfileList();
      save.value = false;
      saved.value = true;
      setTimeout(() => { saved.value = false; }, 1000);
    } else {
      alert("There was an failure when attempting to Save-As the profile.")
    }
  }).catch((error) => {
    console.log('Looks like there was a problem in SaveAsProfile: \n', error);
  });
}

function DeleteProfile() {
  const url = bdlApiPathBase + 'setProfileV8.php';
  fetch(url, {
    headers: {'Content-type': 'application/json'},
    method: 'POST',
    body: JSON.stringify({
      "action": "delete",
      "profilename": currentProfile,
    })
  }).then((res) => res.json()).then((response) => {
    console.log("result", response);
    if (response.result) {
      profile.value = false;
      seriesParams.value = [];
      ClearSeriesData();
      LoadProfileList();
      deleteProfileModal = false;
      deleted.value = true;
      setTimeout(() => { deleted.value = false; }, 1000);
    } else {
      alert("There was an failure when attempting to delete the profile.")
    }
  }).catch((error) => {
    console.log('Looks like there was a problem in DeleteProfile: \n', error);
  });
}

function CleanProfile() {
  let seriesCount = 0;
  seriesParams.value.forEach((value) => {
    value.parameters = JSON.parse(value.parameters);
    if (value.parameters.enabled) {
      if (seriesCount > 4) {
        value.parameters.enabled = false;
      }
      seriesCount++;
    }
  });
}

function showErrorMessage(message = 'Error') {
  alert(message);
}
function StylePlots() {
  let id = 0;
  let seriesCount = 0;
  let foundNewEnabled = false;
  seriesParams.value.forEach((value) => {
    const line = value.parameters;
    id = tagToSeriesMap.indexOf(value.tag);

    //CHECK IF A NEW TAG HAS BEEN ENABLED
    if (id < 0 && line.enabled && tagToSeriesMap.length > 0) {
      foundNewEnabled = true;
    }

    //SET THE CHART PROPERTIES FOR TAGS THAT ARE IN THE LIST
    if (id > -1) {
      //AXIS PARAMS
      chartOptions.value.yAxis[id].title.style.color = line.color;
      chartOptions.value.yAxis[id].labels.style.color = line.color;
      chartOptions.value.yAxis[id].visible = line.yaxis;
      if (line.max == undefined || line.max == '') {
        chartOptions.value.yAxis[id].max = null;
      } else {
        chartOptions.value.yAxis[id].max = Number(line.max);
      }
      if (line.min == undefined || line.min == '') {
        chartOptions.value.yAxis[id].min = null;
      } else {
        chartOptions.value.yAxis[id].min = Number(line.min);
      }
      chartOptions.value.yAxis[id].opposite = line.axisonright;
      chartOptions.value.yAxis[id].title.text = value.name;

      //SERIES PARAMS
      chartOptions.value.series[id].color = line.color;
      chartOptions.value.series[id].fillColor = line.color;
      chartOptions.value.series[id].name = value.name;
      chartOptions.value.series[id].dashStyle = line.dashstyle;
      chartOptions.value.series[id].lineWidth = line.thickness;
      chartOptions.value.series[id].type = line.plottype;
      chartOptions.value.series[id].visible = line.enabled;
      chartOptions.value.series[id].step = line.step;
    }
    if (line.enabled) seriesCount++;
  });

  seriesCountNumber.value = seriesCount;

  //MAKE SURE THAT THEY ONLY ARE USING 5 SERIES
  //AND IF A NEW TAG WAS ENABLED THEN SHOW THE LOAD BUTTON
  if (seriesCount > 5) {
    alert("You can only have 5 series enabled");
  } else {
    if (foundNewEnabled) {
      loadneeded.value = true;
    }
  }
}

function RenderSeriesData(data) {
  chartOptions.value.series[0].data = data.s0;
  chartOptions.value.series[1].data = data.s1;
  chartOptions.value.series[2].data = data.s2;
  chartOptions.value.series[3].data = data.s3;
  chartOptions.value.series[4].data = data.s4;
  chartOptions.value.series[5].data = data.s5;
  chartOptions.value.series[6].data = data.s6;
  chartOptions.value.series[7].data = data.s7;
}

function ClearSeriesData() {
  chartOptions.value.series[0].data = [];
  chartOptions.value.series[1].data = [];
  chartOptions.value.series[2].data = [];
  chartOptions.value.series[3].data = [];
  chartOptions.value.series[4].data = [];
  chartOptions.value.series[5].data = [];
  chartOptions.value.series[6].data = [];
  chartOptions.value.series[7].data = [];
}

function closeColorPickerOnClick() {
  document.getElementsByClassName('configpanel')[0].click();
}

function DarkMode() {
  chartOptions.value.chart.backgroundColor = '#000';
}

function LightMode() {
  chartOptions.value.chart.backgroundColor = '#fff';
}

function GridLinesX(en) {
  if (en) {
    chartOptions.value.xAxis.gridLineWidth = 1;
  } else {
    chartOptions.value.xAxis.gridLineWidth = 0;
  }
}

function GridLinesY(en) {
  if (en) {
    chartOptions.value.yAxis[0].gridLineWidth = 1;
    chartOptions.value.yAxis[1].gridLineWidth = 1;
    chartOptions.value.yAxis[2].gridLineWidth = 1;
    chartOptions.value.yAxis[3].gridLineWidth = 1;
    chartOptions.value.yAxis[4].gridLineWidth = 1;
    chartOptions.value.yAxis[5].gridLineWidth = 1;
    chartOptions.value.yAxis[6].gridLineWidth = 1;
    chartOptions.value.yAxis[7].gridLineWidth = 1;
  } else {
    chartOptions.value.yAxis[0].gridLineWidth = 0;
    chartOptions.value.yAxis[1].gridLineWidth = 0;
    chartOptions.value.yAxis[2].gridLineWidth = 0;
    chartOptions.value.yAxis[3].gridLineWidth = 0;
    chartOptions.value.yAxis[4].gridLineWidth = 0;
    chartOptions.value.yAxis[5].gridLineWidth = 0;
    chartOptions.value.yAxis[6].gridLineWidth = 0;
    chartOptions.value.yAxis[7].gridLineWidth = 0;
  }
}

function tagSelected(tag) {
  const params = JSON.parse(JSON.stringify(defaultParamJson));
  if (tag.tag == undefined || tag.droplet == undefined || tag.tag == "" || tag.droplet == "") {

  } else {
    showTagDialog.value = false;
    seriesParams.value.push({
      "tag": tag.tag,
      "droplet": tag.droplet,
      "name": tag.name,
      "parameters": params
    });
    IsReloadNeeded();
    StylePlots();
  }
}

function RemoveTag(id) {
  // console.log(seriesParams.value,'seriesParams.value')
  seriesParams.value.splice(id, 1);
  deleteRow.value = -1;
  IsReloadNeeded();
}

</script>
<style lang="scss" scoped>
.delete_anything{
  text-align: center;
  h3:first-child{
    text-align: left;
  }
  .parameter{
    font-weight: 500;
  }
}
.saved_prof{
  background-color: #fff;
  width: 500px;
  max-width: calc(100% - 80px);
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  border-radius: 16px;
  h3{
    height: 100px;
    line-height: 100px;
    margin: 0px;
    text-align: center;
  }
}
.save{
  background-color: #fff;
  width: 500px;
  max-width: calc(100% - 80px);
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 16px;
  .profile_name{
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    input{
      -webkit-appearance: none;
      text-indent: 12px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #BCBCBC;
      border-radius: 10px;
      color: #000;
      cursor: pointer;
      height: 40px;
      font-size: 1rem;
      font-weight: 500;
      outline: none;
    }
    p{
      font-size: 1rem;
      display: inline-block;
      height: 45px;
      line-height: 45px;
      margin-right: 45px;
    }
  }
  h3{
    margin: 0 0 20px 0;
    position: relative;
    span{
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-50%);
      display: inline-block;
      height: 150%;
      width: 60px;
      margin-left: auto;
      background-image: url(../../../public/img/boeing/close.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
  .buttons{
    display: flex;
    justify-content: space-between;
  }
}

.datepicker {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  margin: 0;
  margin-right: 20px;
  font-size: 1rem;
  text-align: center;
  color: #252525;
  background-color: #2525251A;
}
.datepicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  font-family: 'Inter', sans-serif;
}
input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  font-family: 'Inter', sans-serif;
}
.content {
  padding: 0 0 20px;
  position: relative;
  height: calc(100% - 80px);
  box-sizing: border-box;
  justify-content: space-between;
  >h3{
    margin: 15px 0;
  }
  .plotarea {
    height: 100%;
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    .plotarea_header {
      height: 80px;
      line-height: 40px;
      background-color: #f8f8f8;
      padding: 20px;
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      flex-direction: row;
      .profiledropdown {
        margin-right: 30px;
        flex: 1;
        flex-grow: 1;
      }
      .button, .reset_zoom {
        font-size: 0.75rem;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        margin-right: 20px;
        cursor: pointer;
      }
      .reset_zoom {
        text-align: center;
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 400;
        box-sizing: border-box;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        background-color: #fff;
        color: #787878;
        text-transform: uppercase;
        border-color: #fff;
        transition: all 0.1s;
      }

      .profile_settings {
        height: 40px;
        width: 40px;
        background-image: url(../../../public/img/boeing/settings.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #787878;
        border-radius: 10px;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
  }

  .profiletile {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 3;
    p{
      padding-left: 20px;
      margin-bottom: 0px;
    }
    .profiletile_top {
      height: 75px;
      padding-left: 20px;
      background-color: #f8f8f8;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h3{
        margin: 0px;
        height: 75px;
        line-height: 75px;
      }
      span{
        display: inline-block;
        height: 75px;
        width: 75px;
        margin-left: auto;
        background-image: url(../../../public/img/boeing/close.svg);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }
    .profilebuttons {
      padding: 20px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      >div {
        color: #252525;
        width: 80px;
        height: 80px;
        line-height: 36px;
        font-size: 1rem;
        // padding-top: 56px;
        text-align: center;
        color: #787878;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: top center;
        // -webkit-touch-callout: none; /* iOS Safari */
        // -webkit-user-select: none; /* Safari */
        // -khtml-user-select: none; /* Konqueror HTML */
        // -moz-user-select: none; /* Old versions of Firefox */
        // -ms-user-select: none; /* Internet Explorer/Edge */
        // user-select: none;
        cursor: pointer;
      }
      >div:nth-child(1){
        background-image: url(../../../public/img/boeing/datatrending/new.svg);
      }
      >div:nth-child(2){
        background-image: url(../../../public/img/boeing/datatrending/save.svg);
      }
      >div:nth-child(3){
        background-image: url(../../../public/img/boeing/datatrending/save_as.svg);
      }
      >div:nth-child(4){
        background-image: url(../../../public/img/boeing/datatrending/delete.svg);
      }
    }

    .profiletilelist {
      overflow-y: auto;
      height: calc(100% - 340px);
      border-radius: 10px;
      padding: 20px 20px 0px;

      .profile_line {
        width: 100%;
        margin-bottom: 20px;
        height: 26px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .line_name {
          font-size: 1rem;
          line-height: 26px;
          height: 26px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          flex-grow: 1;
          position: relative;
        }

        .line_name::after {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 10px;
          content: '';
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C */
          background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6+ */
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1))); /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opera 11.10+ */
          background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* IE10+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
        }

        span {
          margin-right: 10px;
        }

        .linecolor {
          display: inline-block;
          color: white;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          flex-shrink: 0;
          flex-grow: 0;
          margin-right: 8px;
        }

        .listbuttons {
          margin-left: auto;
          flex-shrink: 0;

          .listbutton {
            display: inline-block;
            height: 26px;
            width: 26px;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .listbutton:last-child {
          margin-left: 8px;
        }
        // .listbutton:last-child{
        // 	float: right;
        // }
      }

      .profile_line:last-child {
        margin-bottom: 40px;
      }
    }
  }
  .button_wrapper{
    position: absolute;
    bottom: 0px;
    height: 85px;
    width: 100%;
    background-color: #fff;
    .add_tag_button {
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 20px;
    }
  }
  .loaddatabutton {
    top: 2px;
    position: absolute;
    right: 2px;
    background-color: #aaa;
    color: white;
    border-radius: 5px;
  }

  .configpanel {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    width: 500px;
    z-index: 2;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    padding-top: 90px;
    .configpanel_top{
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      height: 75px;
      padding-left: 20px;
      background-color: #f8f8f8;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h3{
        margin: 0px;
        height: 75px;
        line-height: 75px;
      }
      span{
        display: inline-block;
        height: 75px;
        width: 75px;
        margin-left: auto;
        background-image: url(../../../public/img/boeing/close.svg);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }
    > div:not(:first-child) {
      display: flex;
      margin-top: 16px;
      height: 36px;

      p {
        margin: 0px;
        font-size: 1rem;
        font-weight: 500;
      }

      p:first-child {
        width: 160px;
      }

      *:nth-child(2) {
        flex-grow: 1;
      }

      input {
        appearance: none;
        /*  safari  */
        -webkit-appearance: none;
        /*  other styles for aesthetics */
        font-size: 0.76rem;
        background-color: #fff;
        border: 1px solid #caced1;
        border-radius: 10px;
        color: #787878;
        cursor: pointer;
        outline: none;
        text-indent: 12px;
      }
    }

    button {
      position: absolute;
      bottom: 20px;
      width: calc(100% - 40px);
      margin-top: 32px;
      padding: 0px;
    }
  }

  .tagselectpanel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 2;
    background-color: white;
    border-radius: 16px;
    padding: 32px;

    h3 {
      margin: 0px 0px 32px 0px;
    }

    button {
      margin-top: 32px;
      width: calc(50% - 8px);
      margin-left: 16px;
    }
  }

  .dataspinner {
    text-align: center;
  }

  .profilespinner {
    text-align: center;
  }
}

.toggle {
  width: 57px !important;
}

.context_shadow {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 0.2s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  transform-origin: top right;
  opacity: 0;
  transform: scale(20%);
}
</style>
  
  
  